import { usePlatformTheme } from '@/components/common/ThemeContext';
import { PlatformTheme } from '@/components/common/ThemeContext/typedefs';
import { AchievementIconOptions } from '@/components/platform/Achievements/components/AchievementIcon';

export const useThemedLockedIcon = (
  icons: Pick<AchievementIconOptions, 'iconLockedUrl' | 'iconLockedDarkUrl'>,
): string | undefined => {
  const { iconLockedUrl, iconLockedDarkUrl } = icons;
  const { theme } = usePlatformTheme();

  return (
    theme === PlatformTheme.Dark
      ? iconLockedDarkUrl
      : iconLockedUrl
  );
};
