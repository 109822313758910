export enum TagColorMode {
  Gray = 'gray',
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Purple = 'purple',
  Pink = 'pink',
  Orange = 'orange',
  None = 'none',
}
