import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const AchievementsEvents = {
  sendEvent: (event: string, data?: Record<string, any>) => {
    const eventName = `achievements_${event}`;

    sendAmplitudeData(eventName, data);
    GoogleAnalyticsClient.getInstance().sendEvent(eventName, data);
  },
  events: {
    blockShowAllClicked: 'block_show_all_clicked',
    blockAchievementItemClicked: 'block_achievement_item_clicked',
    flashMessageOpenButtonClicked: 'flash-message_open-button_clicked',
    pageAchievementModalOpened: 'page_achievement-modal_opened',
  },
};
